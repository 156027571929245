body {
  /* background: linear-gradient(141deg, #8BA3FF 100%, #719DF6 100%, #6B83F6 100%); */
  background: #fff;
}

#header {
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-height: 95vh;
  -webkit-transform: skewY(-12deg);
  -ms-transform: skewY(-12deg);
      transform: skewY(-12deg);
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
      transform-origin: 0;
  z-index: -1;
  background-image: url(/images/bg.svg);
  background-size: cover;
  background-repeat: repeat;
  background-color: #6f90f7;
}

.footer {
  position: relative;
  background: transparent;
  overflow: hidden;
  padding-top: 500px;
  margin-top: -480px;
  pointer-events: none;
}

.footer:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 13rem;
  height: 6000px;
  background-image: url(/images/bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #6f90f7;
  -webkit-transform: skew(0, -12deg);
      -ms-transform: skew(0, -12deg);
          transform: skew(0, -12deg);
  z-index: -1;
}

h2{
  color: #6c63ff;
}

.purple {
  color: #6c63ff!important;
}

.landing-page h1 {
  width: 100%;
  max-width: 40rem;
  margin: 0rem auto;
  text-align: center;
  display: block;
  line-height: 1.3;
  padding-top:4rem;
}

.login-button button {
  position: absolute;
  right:0;
}

.purple-line {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20rem solid transparent;
  border-left: 20rem solid #f1f0ff;
  border-bottom: 20rem solid transparent;
  z-index: -1;
}

.purple-line-two {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20rem solid transparent;
  border-right: 20rem solid #f1f0ff;
  border-bottom: 20rem solid transparent;
  right:0;
  top: 110rem;
  z-index: -1;
}

.purple-line-three {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20rem solid transparent;
  border-right: 20rem solid #f1f0ff;
  border-bottom: 20rem solid transparent;
  right:0;
  /* top: 45rem; */
  z-index: -1;
}

.purple-line-four {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20rem solid transparent;
  border-left: 20rem solid #f1f0ff;
  border-bottom: 20rem solid transparent;
  z-index: -1;
  top: 155rem;
}

.purple-line-five {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20rem solid transparent;
  border-right: 20rem solid #f1f0ff;
  border-bottom: 20rem solid transparent;
  right:0;
  z-index: -1;
  top: 205rem;
}

.purple-line-six {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20rem solid transparent;
  border-left: 20rem solid #f1f0ff;
  border-bottom: 20rem solid transparent;
  z-index: -1;
  top: 265rem;
}

.purple-line-seven {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20rem solid transparent;
  border-right: 20rem solid #f1f0ff;
  border-bottom: 20rem solid transparent;
  right:0;
  z-index: -1;
  top: 311rem;
}

.purple-line-eight {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20rem solid transparent;
  border-left: 20rem solid #f1f0ff;
  border-bottom: 20rem solid transparent;
  z-index: -1;
  top: 375rem;
}

.downloads button {
  width: 200px;
}

.downloads {
  padding: 0 10rem;
}

.downloads img {
  max-width: 2rem;
  display: inline-block;
  padding-right: .5rem;
  padding-bottom: .3rem;
}

img.icon {
  max-width: 15rem;
}

#header,
#header span {
  position: absolute;
}

.how-it-works {
  max-width: 40rem;
}

ul.business_benefits > li,
ul.user_benefits > li {
  text-align: left;
  list-style: disc;
}

ul.business_benefits,
ul.user_benefits {
  padding: 1rem 5rem;
  line-height: 1.9;
}

.arrow {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  width: 100%;
  max-width: 40px;
  margin: -7px 5px;
}

.app__screenshots img {
  width: 100%;
  max-width: 18rem;
}

.circle,
.circle-1,
.circle-2 {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #fff;
}

.circle {
  right: -3rem;
  top: 11rem;
  z-index: 2;
}

.circle-1 {
  right: -3rem;
  top: 11rem;
  z-index: 2;
}

.circle-2 {
  right: -3rem;
  top: 11rem;
  z-index: 2;
}

.phones {
  width: 30rem;
}

.app__screenshots ul > li {
  display: inline-block;
}

.app__screenshots li {
  margin: 0 1rem;
}

.business-owners img {
  -webkit-box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
          box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
  image-rendering: -moz-crisp-edges;         /* Firefox */
  image-rendering:   -o-crisp-edges;         /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
}

.business-owners h3 {
  text-align: left;
  margin-left: 2rem;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight:700;
  color: #6c63ff;
}

.business-owners p {
  font-size: 1.625rem;
  line-height: 2.25rem;
  letter-spacing: -.03062rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: rgba(0,0,0,.85);
  text-align: left;
  margin-left: 2rem;  
}

img.slider-5 {
  max-width: 17.5rem;
}

/* Carousel*/
input#carousel-1,
input#carousel-2,
input#carousel-3,
input#carousel-4 {
  display: none;
}
.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  -o-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3,
#carousel-4:checked ~ .control-4 {
  display: block;
}

.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}

#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet,
#carousel-4:checked
  ~ .control-4
  ~ .carousel-indicators
  li:nth-child(4)
  .carousel-bullet {
  color: #2b6cb0; /*Set to match the Tailwind colour you want the active one to be */
}

/* Video Player */

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  top: -1.5rem;
}
.videoWrapper > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media Queries */
@media screen and (max-width:1024px) {
  .circle,
  .circle-1,
  .circle-2 {
    top: 23rem;
    right: 0;
    left: 0;
    display: block;
    z-index: 2;
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
    margin: 0 auto;
  }
  .first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .business-owners h3, .business-owners p {
    margin-left:0;
    font-size: 1.875rem;
    text-align: center;
  }

  .business-owners p {
    font-size:1rem;
  }
  
  .business-owners img {
    margin-top: -1.5rem;
  }
}
@media screen and (max-width:478px) {
  .circle {
    top:25rem;
  }
}
@media screen and (max-width:640px) {
  .landing-page h1 {
    max-width: 25rem;
  }
  #header {
    max-height: 100vh;
  }
  .downloads {
    padding: 0 0;
  }
}
@media screen and (min-width:1024px) {
  .downloads {
    padding: 0 6rem;
  }
}
@media screen and (max-width: 767px) {
  .downloads {
    padding: 0 6rem;
  }
  ul.business_benefits,
  ul.user_benefits {
    padding: 1rem 3rem;
  }
  .phones {
    width: 20rem;
  }
  .footer {
    display:none;
  }
  img.slider-5 {
    max-width: none;
  }
}