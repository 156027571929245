* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
:focus {
  outline: none;
}
button {
  -webkit-appearance: none;
  border: none;
  background: transparent;
}
nav {
  padding: 2rem 1.2rem;
  height: 100vh;
  background-color: #54d0c3;
  background-image: url(/images/skyscraper.png);
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
nav h1 {
  font-weight: 700;
}
nav small {
  font-size: 0.8rem;
  color: #99a2a8;
  font-weight: 400;
}
nav .btn-logout {
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  position: absolute;
  right: 1.5rem;
  bottom: 2rem;
  color: #adf7ed;
}
@media (max-width: 975px) {
  nav {
    display: none;
  }
}
nav .image {
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
}
nav .received-image {
  left: -3rem;
  transform: none;
}
nav .pickup-image {
  width: 17rem;
}
nav .complete-image {
  width: 13rem;
}
body {
  font-family: roboto, sans-serif;
  font-weight: 400;
  background-color: #f2f4f4;
}
.wrapper {
  display: block;
  max-width: 1600px;
  margin: 0 auto;
  overflow: hidden;
  height: 100vh;
}
.wrapper > .data {
  background-color: #fff;
  /* box-shadow: 0 2px 10px 0 #e5e6e6; */
  border: 1px solid #ececec;
  border-radius: 0.4rem;
  height: 100%;
  overflow: hidden;
  /* display: grid;
  grid-template-rows: 4rem 4.2rem 1fr;
  grid-template-rows: 7rem 4.5rem 4.5rem 1fr; */
}

.data-content {
  height: calc(100% - 80px);
}

.data > .intro {
  padding: 1.5rem;
  border-bottom: 1px solid #eef4f4;

  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 65%;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 -0.4rem 1rem 0 #e8e8e8;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  transform: translateY(100%);
  transition: all 0.2s ease-out;
}
.data > .intro.-open {
  transform: translateY(0);
}
.data > .intro .user {
  margin-bottom: 1rem;
}
.data > .list {
  height: 100%;
  display: grid;
  grid-template-rows: 2fr 1fr;
}
.data .tabs-pane {
  /* display: grid;
  grid-template-rows: 3rem 1fr; */
  height: 100% !important;
}
.data .tabs-pane .tabs {
  padding: 1rem 1.7rem;
}
.data .tabs-pane .tabs li a {
  font-weight: 300;
  font-size: 0.85rem;
}
.data .tabs-pane .table .list:first-of-type {
  display: none;
}
.data .tabs-pane#potential .table .list,
.data .tabs-pane#complete .table .list {
  grid-template-columns: 1.6fr 1fr 1.5fr 1fr 1fr 1fr 1fr 4rem;
}

.data .tabs-pane#masterlist > section {
  display: grid;
  grid-template-rows: 3rem 7rem 1fr;
}

.data .tabs-pane#archive > section {
  height: 100%;
  display: grid;
  grid-template-rows: 3.4rem 1fr;
}

.data .tabs-pane#access > section {
  height: 100%;
  display: grid;
  grid-template-rows: 3.4rem 1fr;
}

.data .tabs-pane#orders > section {
  display: grid;
  grid-template-rows: 4rem 1fr;
}

.data .tabs-pane#orders > section > .list {
  display: grid;
  grid-template-rows: 1.5fr 1fr;
}

.data .tabs-pane#archive > section .table.list-content,
.data .tabs-pane#access > section .table.list-content,
.data .tabs-pane#orders > section {
  height: 100%;
}

.user {
  padding: 1rem;
}

.user h1 {
  font-size: 1.5rem;
  font-weight: 600;
}

.form {
  display: flex;
  align-items: center;
}
.search {
  padding: 0.3rem 1rem;
  background-color: #ebebeb;
  border-radius: 0.3rem;
  text-align: left;
  font-weight: 500;
  flex: 1;
}
.label {
  font-size: 0.75rem;
  letter-spacing: 0.03rem;
  margin-right: 0.3rem;
  font-weight: 300;
  color: gray;
}
.intro .title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: -0.3rem;
}
.intro .title p {
  font-size: 0.7rem;
  font-family: sans-serif;
  font-weight: 300;
  letter-spacing: 0.03rem;
  color: #737373;
}
.list > .data {
  overflow-y: auto;
  height: 100%;
  background-color: #f1f7f6;
  box-shadow: 0 1rem 1rem -1.2rem #dbe0df inset;
}
.list > .data > * {
  height: 0;
}
.list > .map {
  padding: 1rem;
}
.list-content .list {
  margin: 0.6rem 0 0 !important;
}
.list-allbranches {
  padding: 1rem 0;
}
.list-branches {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr !important;
  padding: 0 2.5rem !important;
}
.table {
  padding: 0 1rem;
  display: block !important;
  overflow-y: auto;
}
.table .list {
  align-items: center;
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: 0.01rem;
  padding: 0.5rem 0.8rem 1rem;
  margin: 0.4rem 0 1rem;
  border-radius: 0.3rem;
  border: 1px solid transparent;
  border-bottom-color: #eef4f4;
}
.table .list .table .list p {
  line-height: 1.3;
  padding: 0 1rem 0 0;
  margin-bottom: 0.5rem;
}
.table .list p {
  margin-bottom: 0.5rem;
  position: relative;
  clear: both;
}
.table.list-content {
  background-color: #f1f7f6;
  box-shadow: 0 1rem 1rem -1.2rem #dbe0df inset;
  padding: 0.4rem 0.5rem;
  overflow-y: auto;
  height: 83%;
}

.table .list p:before {
  font-size: 0.75rem;
  font-weight: 300;
  color: #969292;

  display: none;
}
.table .list p:first-of-type:before {
  content: 'Order Status';
  margin-bottom: 0.5rem;
}
.table .list p:nth-of-type(2):before {
  content: 'Customer';
}
.table .list p:nth-of-type(3):before {
  content: 'Date/Time';
}
.table .list p:nth-of-type(4):before {
  content: 'Last Notification';
}
.table .list p:nth-of-type(5):before {
  content: 'Request';
}
.table .list p:nth-of-type(6):before {
  content: 'Request Process';
}
.table .list p:nth-of-type(7):before {
  content: 'Time/Sender name';
}

.table .list p:nth-of-type(6) img {
  width: 1rem;
  margin-right: 0.5rem;
  margin-top: 0.15rem;
  float: left;
}
.table .list.-masterlist {
  grid-template-columns: 0.5fr 1fr 0.5fr 1fr 1fr 1fr 1fr !important;
  padding: 0 1.7rem;
}
.table .list.-masterlist:first-of-type {
  margin: 1rem 0 1.2rem;
}
.table .list.-masterlist.-active {
  background-color: #d7f3f0;
  border-color: #1b9f92;
}
.table .list.-masterlist img {
  width: 2.5rem;
  margin: 0.4rem 0;
}
.table .list p:last-of-type {
  margin-bottom: 0;
}
.table .list p:last-of-type img {
  width: 1.1rem;
  display: inline-block;
  margin-right: 0.2rem;
}
/* .table .list small:last-of-type,
.list small:nth-of-type(5) {
  text-align: center;
} */
.list-actions {
  margin-top: -3rem;
  text-align: center;
  margin-left: auto;
  margin-right: 0;
  width: 4rem;
  display: flex;
  position: relative;
}
.list-actions button {
  font-weight: 300;
  margin: 5px 8px 0;
  color: #1b9f92;
  font-size: 20px;
}
/* .list-actions button.delete {
  position: absolute;
  font-size: 0.7rem;
  font-weight: 600;
  width: 8rem;
  left: -60px;
  top: 34px;
  background-color: #c71515;
  box-shadow: 0 2px 10px -6px #c71515;
  color: #fff;
  padding: 4px;
  border-radius: 40px;
} */
.list small {
  font-weight: 300;
  font-size: 0.75rem;
  color: gray;
  display: block;
}
.table .no-orders {
  text-align: center;
  padding: 7rem 0;
  color: gray;
}
.table .-active.potential {
  background-color: #ffe4e4;
  border-color: #dc5150;
}
.table .-active.potential p:first-of-type {
  color: #d44443;
}
.table .-active.received {
  background-color: #ffe4d4;
  border-color: #f19b69;
}
.table .-active.received p:first-of-type {
  color: #f19b69;
}
.table .-active.started {
  background-color: #c6e0f9;
  border-color: #1670c3;
}
.table .-active.started p:first-of-type {
  color: #1670c3;
}
.table .-active.almost {
  background-color: #ede6fb;
  border-color: #6b34e1;
}
.table .-active.almost p:first-of-type {
  color: #6b34e1;
}
.table .-active.pickup {
  background-color: #fff5e6;
  border-color: #ffa21a;
}
.table .-active.pickup p:first-of-type {
  color: #ffa21a;
}
.table .-active.delivery {
  background-color: #fbf4bd;
  border-color: #efd60d;
}
.table .-active.delivery p:first-of-type {
  color: #b3a10d;
}
.table .-active.complete {
  background-color: #d9eeda;
  border-color: #4caf50;
}
.table .-active.complete p:first-of-type {
  color: #4caf50;
}
.table .status {
  line-height: 1.2;
}
.table .status img {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  margin-top: -0.2rem;
  float: left;
}
.table-info {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  border-bottom: 1px solid #eef4f4;
}
.table-info img {
  display: block;
  margin-right: 1rem;
  width: 4rem;
  height: auto;
}
.table-info h3 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
}
.table-info h3 span {
  display: block;
  font-size: 70%;
  font-weight: 400;
}
.customer > .info {
  display: block;
  font-size: 0.85rem;
  font-weight: 500;
}
.tabs-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem !important;
  border-bottom: 1px solid #eef4f4 !important;
}
.tabs-main .tabs {
  padding: 0 !important;
  grid-template-columns: 8rem 7rem 8rem 7rem;
}
.tabs-main > div {
  display: none;
}
.breadcrumbs {
  margin: 0.5rem 1.8rem 1rem;
}
.breadcrumbs > a {
  color: #12736a;
  text-decoration: underline;
}
.breadcrumbs > a:after {
  content: '';
  display: inline-block;
  font-family: 'Ionicons';
  color: #c1c7c7;
  margin-left: 0.5rem;
}
.breadcrumbs > * {
  font-size: 80%;
  margin-right: 0.5rem;
}
.tabs {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1rem !important;
  /* margin-right: 1rem; */
  /* border-bottom: 1px solid #eef4f4 !important; */
  display: grid;
  grid-template-columns: 8rem 7rem 7rem 10rem 10rem 10rem 7rem;
  align-items: center;
  text-align: center;
  /* height: 2.1rem; */
}
.tabs li a {
  position: relative;
  border-radius: 0.4rem !important;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5em 0 !important;
  display: block;
}
.tabs li a.-hasnotif:after {
  content: attr(data-count);
  font-size: 0.7rem;
  font-weight: 800;
  width: 20px;
  height: 20px;
  color: #fff;
  background: #f73e3e;
  border-radius: 100%;
  margin-left: 0.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tabs li.user-tab.active a {
  background-color: transparent !important;
  color: #000;
}
.tabs li.user-tab a {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  padding-left: 0.8rem !important;
  border-radius: 0 !important;
}
.tabs li.user-tab img {
  width: 2rem;
  margin-right: 0.5rem;
}

.profile {
  display: flex;
  align-items: center;
  padding: 0 0 1rem 0;
}
.profile > .name {
  font-size: 1.2rem;
  font-weight: 600;
}
.profile > .name > .address {
  display: block;
  font-size: 75%;
  font-weight: 500;
}
.profile > .image {
  border-radius: 100%;
  width: 3.5rem;
  height: 3.5rem;
  display: block;
  overflow: hidden;
  margin-right: 0.8rem;
}
.process {
  position: relative;
  margin-top: 1rem;
}
.process > .list {
  color: #15796f;
  font-size: 0.95rem;
  font-weight: 500;
  text-align: left;
  margin: 0.2rem 0;
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.1s ease-out;
  position: relative;
  z-index: 2;
}
.process > .list label {
  cursor: pointer;
  line-height: 1.3;
}
.process > .list.-active {
  color: #084640;
  font-weight: 600;
}
.process > .list:before {
  content: '';
  box-sizing: border-box;
  width: 1.2rem;
  height: 1.2rem;
  border: 0.1rem solid #0d9284;
  background-color: #f2f4f4;
  display: inline-block;
  margin-right: 1rem;
  border-radius: 100%;
  position: relative;
  z-index: 2;
}
.process > .list:hover {
  background-color: rgba(133, 230, 220, 0.93);
}
.process:before {
  content: '';
  width: 0.1rem;
  height: calc(100% - 2rem);
  display: inline-block;
  position: absolute;
  top: 1rem;
  left: 1.55rem;
  border-left: 2px dotted #1a988b;
}
.process .-active:before {
  z-index: 2;
  width: 1.3rem;
  height: 1.3rem;
  background-image: url('/images/check-icon.svg');
  background-size: cover;
  border: none;
}
.process .-locked,
.process .-unlocked {
  flex-wrap: wrap;
  padding: 0.4rem 1rem 0.2rem;
}
.process .-locked {
  pointer-events: none;
  cursor: not-allowed;
}
.process .-unlocked {
  pointer-events: auto;
}
.process .timer {
  min-width: 26%;
  margin: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #013954;
  font-weight: 600;
  font-size: 0.75rem;
  background: #fee519;
  padding: 0.2rem 0.4rem;
  border-radius: 10rem;
}
.process .-unlocked label,
.process .-locked label {
  max-width: 70%;
}
.process .timer img {
  margin-right: 0.4rem;
  width: 0.9rem;
}
.no-apprearance,
.intro .search {
  -webkit-appearance: none;
  border: none;
}
[role='tablist'],
[role='tab'][aria-selected='true'],
[role='tab']:hover:not([aria-selected='true']) {
  border-color: transparent !important;
  background: transparent !important;
}
[role='tab'] {
  position: relative;
  border-radius: 0.4rem !important;
  color: #000;
  font-size: 0.8rem;
  font-weight: 600;
  margin-right: 0.5rem;
  padding: 0.5em 1.5em !important;
}
[role='tab'].-hasnotif:before {
  content: attr(data-count);
  font-size: 0.65rem;
  width: 1rem;
  height: 1rem;
  display: block;
  color: #fff;
  background: red;
  border-radius: 100%;
  position: absolute;
  top: -0.6rem;
  right: -0.1rem;
  margin: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
[role='tablist'] {
  padding: 1rem 1.7rem !important;
  border-bottom: 1px solid #eef4f4 !important;
}
[role='tabpanel'] {
  padding: 0 1.7rem;
}
[role='tab'][aria-selected='true'] {
  background-color: #000 !important;
  color: #fff;
} /*# sourceMappingURL=style.min.css.map */

/* .intro .user {
    position: relative;
    padding-left: 3rem;
  } */

.intro .user img {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0.3rem;
}

.intro-mobile {
  position: absolute;
  left: 0;
  top: 1rem;
  z-index: 2;
  background-color: #fff;
  padding: 0.4rem 0.8rem;
}

[data-testid='wrapper'] > div {
  /* height: 100vh; */
}

[data-testid='wrapper'] {
  position: initial;
}

.sidebar {
  width: 100%;
  height: 100%;
  padding: 1rem;
  /* box-shadow: 0 2px 10px 0 #e5e6e6; */
  overflow: hidden;

  background-color: #6cd4ca;
  background-image: url(/images/skyscraper.svg);
  background-repeat: no-repeat;
  background-position: center 140%;
  background-size: contain;

  position: fixed;
  bottom: 0;
  z-index: 20;
  transform: translateY(100%);
  transition: all 0.2s ease-out;

  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.sidebar i {
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.8rem 1rem;
  color: #1b9f92;
}

.sidebar.-open {
  transform: translateY(3%);
  padding-top: 1.5rem;
}

.sidebar .graphic {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  width: 100%;
  display: none;
}

@media screen and (orientation: landscape) and (min-width: 600px) and (min-height: 200px) {
  .wrapper {
    overflow: auto;
  }
  .list > .map {
    padding: 0;
  }
  .table {
    width: 200%;
  }
  .table .list {
    display: grid;
    grid-template-columns: 0.7fr 0.6fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0.5rem;
    overflow: visible;
  }
  .table .list p {
    margin-bottom: 0;
  }
  .table .status {
    text-align: left;
  }
  .data .tabs-pane .table .list:first-of-type {
    display: grid;
  }
  .intro .title p {
    font-size: 0.8rem;
  }
  .data > .list {
    grid-template-rows: 1.5fr 1fr;
  }
  .sidebar {
    right: 0;
    width: 50%;
    overflow-y: scroll;
    border-radius: 0;
    transform: translateX(100%);
  }
  .sidebar.-open {
    transform: translateX(0);
    padding: 1.5rem 1rem 3rem;
    background-position: center bottom;
  }
}

.data > .tabs li.active a[data-tab='true'],
.tabs li.active a {
  color: #fff;
}
.data .tabs-main > .tabs li:first-child.active a[data-tab='true'] {
  background-color: #1b9f92;
}
.data .tabs-main > .tabs li:last-child.active a[data-tab='true'] {
  background-color: #2d69b3;
}
.tabs li:first-of-type.active a[data-tab='true'] {
  background-color: #d44444;
}
.tabs li:nth-of-type(2).active a[data-tab='true'] {
  background-color: #f19b69;
}
.tabs li:nth-of-type(3).active a[data-tab='true'] {
  background-color: #1770c4;
}
.tabs li:nth-of-type(4).active a[data-tab='true'] {
  background-color: #744ccc;
}
.tabs li:nth-of-type(5).active a[data-tab='true'] {
  background-color: #fbb146;
}
.tabs li:nth-of-type(6).active a[data-tab='true'] {
  background-color: #ead800;
}
.tabs li:nth-of-type(7).active a[data-tab='true'] {
  background-color: #4caf4f;
}

.message-notif {
  font-size: 0.8rem;
  background-color: #ffeedd;
  padding: 0.5rem 1rem;
  display: block;
  margin: 0.5rem 1rem;
  text-align: center;
  border-radius: 0.2rem;
  color: #a57305;
  font-weight: 500;

  display: none;
}
/* .message-notif a {
  text-decoration: underline;
} */

.btn-logout {
  font-size: 90%;
  text-decoration: underline;
  color: #b75555;
}

.btn-remove {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  background-color: #de1818;
  padding: 0.8rem 0.5rem;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
}

.toastify {
  z-index: 9999 !important;
}

.toastify--top-right {
  right: 1em !important;
}

.toastify-content {
  background-color: #fff !important;
  border-radius: 0.3rem !important;
  padding: 15px 25px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video .toastify-content {
  background-color: #354f5d !important;
}
.video .toastify__body span {
  color: #939a9e;
}
.video .toastify__body span em {
  color: #fff;
}

.photo .toastify-content {
  background-color: #fde74d !important;
}
.photo .toastify__body span {
  color: #8c802c;
}
.photo .toastify__body span em {
  color: #544b0a;
}

.toastify__body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toastify__body > div {
  display: flex;
  align-items: center;
}

.toastify__body img {
  width: 2rem;
  margin: 0 0.8rem 0 0;
}

.toastify__body span {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.4;
  color: #888888;
  width: 85%;
}

.toastify__body span em {
  font-weight: 600;
  color: #000;
  font-style: normal;
}

@media screen and (min-width: 820px) {
  #root {
    overflow: auto;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 3.5fr 1.1fr;
    grid-gap: 1rem;
    min-height: 100vh;
    height: 100vh;
    padding: 2rem 0;
  }
  /* .wrapper > .data {
    display: grid;
    margin: 0 1rem;
    grid-template-rows: 5rem 4rem 3.5rem 1fr;
  } */
  .tabs {
    display: flex;
    align-items: center;
    padding: 1rem !important;
    overflow: hidden;
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    border-bottom: none !important;
    grid-template-columns: 7rem 7rem 7rem 10rem 10rem 10rem 7rem;
  }
  .tabs li a {
    margin-right: 0.5rem;
    padding: 0.5em 1.5em !important;
  }
  .tabs-main > div {
    display: block;
  }
  .tabs li.user-tab a {
    border-right: 1px solid #e6efee;
  }
  .data > .intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
    position: initial;
    height: 100%;
    transform: none;
    border-radius: inherit;
  }
  .data > .intro .user {
    margin-bottom: 0;
  }
  .data .tabs-pane .table .list:first-of-type {
    display: grid;
  }
  .data .tabs-pane .tabs li a {
    padding: 0.3em 1.5em !important;
  }
  .list > .map {
    padding: 1rem;
  }
  .intro-mobile {
    display: none;
  }
  .table {
    padding: 0 1.2rem;
    width: 100%;
  }
  .table .list {
    border-bottom-color: transparent;
    padding: 0.65rem 0.8rem;
    margin: 0.4rem 0;
  }
  .table .list p {
    margin-bottom: 0;
  }
  .table .list p:before {
    display: none;
  }
  .table .list.-active {
    border-bottom-width: 1px !important;
  }
  .table.list-content {
    padding: 0.4rem 1.7rem;
  }
  .table .status img {
    margin-top: 0;
  }
  .table .status {
    display: flex;
    align-items: center;
  }
  .table .status img {
    float: none;
  }
  .table .list {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr 1fr 1fr 1fr 1fr;
  }
  .table .list p:nth-of-type(5) {
    text-align: center;
    text-transform: lowercase;
  }
  .table .list p:last-of-type {
    justify-content: center;
  }
  .data .tabs-pane#orders > section {
    grid-template-rows: 3rem 3rem 1fr;
  }
  .message-notif {
    font-size: 0.85rem;
    margin: 0.5rem 1rem;
    display: block;
  }
  .sidebar {
    position: relative;
    transform: translateY(0) !important;
    border-radius: 0.4rem !important;
    background-position: center bottom;
    width: 100%;
    overflow: hidden;
  }
  .sidebar i {
    display: none;
  }
  .sidebar .graphic {
    bottom: 0;
    display: block;
  }
}
