body {
  font-family: sans-serif;
}

.required-field {
  font-size: 12px;
  color: red;
}

.login-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: #6cd4ca;
  background-image: url(/images/skyscraper.svg);
  background-repeat: no-repeat;
  background-position: center 105%;
  background-size: contain;
  box-shadow: 0 0.4rem 1rem 0 rgba(69, 152, 144, 0.5);
}

.login-inner {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.login {
  background-color: #fff;
  width: 100%;
  margin: 1rem auto;
  padding: 1.5rem;
  border-radius: 0.2rem;
  overflow: hidden;
  box-shadow: 0 9px 20px -5px #2744416e;
}

.login a {
  color: #1b8d9f;
  margin-top: 1.5rem;
  display: block;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
}

.login a.back {
  display: inline-block;
  margin: 0 0 0.5rem;
}

.login button {
  display: block;
  padding: 0.8rem;
  text-align: center;
  background-color: #1b8d9f;
  margin-top: 2rem;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: 700;
  width: 100%;
}

.login-title {
  font-size: 2.3rem;
  font-weight: 900;
  font-family: 'Public Sans', sans-serif;
  text-align: center;
  text-transform: capitalize;
  line-height: 1.1;
  color: #fff;
  margin: 0 1rem 1rem;
}

.login-phone {
  max-width: 100%;
}

.login-btn {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  background-color: #2ccabd;
  padding: 1rem;
  border-radius: 0.7rem;
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;
  box-shadow: 0 0 1rem 0 #18988c;
}

.login-extra {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2rem;
  border-top: 1px solid #e8f3f1;
  margin: 2rem -1.5rem -1.5rem;
}

.login-extra small {
  flex: 1;
}
.login-extra a {
  font-weight: 500;
  color: #527572;
  margin: 0;
  padding: 0.8rem;
  display: block;
  transition: background-color 0.1s ease-out;
}

.login-extra a:hover,
.login-extra a:active,
.login-extra a:focus {
  background-color: #e3f5f3;
  color: #1b9f92;
}

.copyright {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  font-size: 0.75rem;
  color: #cbf1ed;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .login {
    width: 25rem;
    display: block;
  }
  .login-phone {
    max-width: 38%;
    margin: 0 5rem 0 auto;
  }
  .login-title {
    font-size: 3.5rem;
    margin-bottom: 2rem;
    max-width: 35rem;
  }
  .login-cont {
    background-repeat-x: repeat;
    background-repeat-y: no-repeat;
    background-size: 30%;
  }
}

@media screen and (orientation: landscape) and (min-width: 600px) and (min-height: 200px) {
  .login-cont {
    height: auto;
    padding: 2rem 0;
  }
  .login-title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .copyright {
    position: relative;
    bottom: 6%;
  }
}

@media screen and (min-width: 820px) {
  .login-cont {
    height: 100vh;
  }
  .copyright {
    position: absolute;
    bottom: 1rem;
  }
}
